// 时间搓格式化
export function formatDate(date, format) {
  let choice = {
    yyyy: date.getFullYear(),
    yy: date
      .getFullYear()
      .toString()
      .slice(-2),
    MM: ("0" + (date.getMonth() + 1)).slice(-2),
    M: date.getMonth() + 1,
    dd: ("0" + date.getDate()).slice(-2),
    d: date.getDate(),
    HH: ("0" + date.getHours()).slice(-2),
    H: date.getHours(),
    hh: ("0" + (date.getHours() % 12)).slice(-2),
    h: date.getHours() % 12,
    mm: ("0" + date.getMinutes()).slice(-2),
    m: date.getMinutes(),
    ss: ("0" + date.getSeconds()).slice(-2),
    s: date.getSeconds(),
    w: ["日", "一", "二", "三", "四", "五", "六"][date.getDay()],
  };
  return format.replace(/[A-z]+/g, function (str) {
    return choice[str];
  });
}



export function fun(year, week) {
  var d = new Date(year, 0, 1);
  var time = d.getTime();
  var t = week * 7 * 24 * 3600 * 1000;
  time += t;
  d = new Date(time);
  var w = d.getDay();
  var d1 = new Date(time - (w - 1) * 24 * 3600 * 1000);
  var d2 = new Date(time + ((7 - w) * 24 * 3600 * 1000));
  return [print(d1), print(d2)]
}
function print(d) {
  var y = d.getFullYear();
  var m = d.getMonth() + 1;
  m = m < 10 ? "0" + m : "" + m;
  var day = d.getDate();
  day = day < 10 ? "0" + day : "" + day;
  return (y + "-" + m + "-" + day);
}


export function getWeek(date) {
  date = formatDate(date, 'yyyy-MM-dd');//将日期转换成yyyy-mm-dd格式
  date = new Date(date);
  var date2 = new Date(date.getFullYear(), 0, 1);
  var day1 = date.getDay();
  if (day1 == 0) day1 = 7;
  var day2 = date2.getDay();
  if (day2 == 0) day2 = 7;
  let d = Math.round((date.getTime() - date2.getTime() + (day2 - day1) * (24 * 60 * 60 * 1000)) / 86400000);
  //当周数大于52则为下一年的第一周
  if ((Math.ceil(d / 7) + 1) > 52) {
    return [date.getFullYear() + 1, 1]
  }
  return [date.getFullYear(), (Math.ceil(d / 7))]
}

// 时间格式化 yyyy-MM-dd  conts 前几天
export function datainfor(conts = 0) {
  let myDate = new Date();
  myDate.setDate(myDate.getDate() - conts);
  const dt = myDate;
  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");
  return `${y}-${m}-${d}`;
}


/**
 * 比较两个日期大小
 * @param a  yyyy-MM-dd
 * @param b   yyyy-MM-dd
 */
export function sortDate(a, b) {
  const arr1 = a.split('-');
  const arr2 = b.split('-');
  let i = 0;
  while (i < arr1.length) {
    if (arr1[i] === arr2[i]) {
      i++
    } else {
      return arr1[i] - arr2[i];
    }
  }
  return false
}